@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&display=swap');

html,
body {
	margin: 0;
	font-size: 100%;
	font-family: 'Lato', sans-serif;
	background: #fff;
}

.container {
	padding: 0;
}

.till-logo-name {
	font-family: 'Montserrat'
}

.header_bottom_logo {
	width: 16%;
	height: 55px;
}

.font-web {
	font-family: 'Montserrat', sans-serif;
	display: inline-block;
}

h1 .font-web {
	margin-top: 10px;
}

.mt {
	margin-top: 4rem;
}

p.h3.mt {
	margin-top: 4rem;
}

.d-flex {
	display: flex;
}

.level-image img {
	height: 277px;
}

.level-image p {
	margin: 0;
}

.p-width {
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
}

.symbols ul {
	padding: 0;
}

.our_mission p {
	margin-bottom: 12px;
}

.enthusiasm {
	margin-bottom: 5rem;
}

.level-image.justify-content {
	justify-content: space-between;
	align-items: center;
}

.mandDescp.blue_clr {
	font-size: 25px;
	font-weight: bold;
}

.level-image .mandDescp {
	margin-bottom: 22px;
}

.mandDescp.align-left {
	text-align: start;
	text-decoration: underline;
	color: #071d7a;
}

.justify-content {
	justify-content: center;
}

.align-left {
	text-align: left;
}

.align-left.mt {
	margin-top: 2rem !important;
}

.make-diff {
	margin-top: 2rem;
}

.font-web-b {
	font-family: 'Montserrat', sans-serif;
	font-size: 28px;
}

.modernregular {
	font-family: Brush Script MT;
	font-size: 28px;
	display: inline-block;
}

.mandala_log_sides p b {
	font-family: 'Montserrat', sans-serif;
}

.buttom_log_sides {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	margin-bottom: 8px;
	position: relative;
}
.mandala_log_sides{
	position: absolute;
	left: -165px;
	bottom: 0;
}
.mandala_log_sides.text-left{
	left: auto;
	right: -226px;
}
.buttom_log_sides p {
	margin: 0;
}

.text-none {
	text-decoration: none !important;
	color: #ffffff !important;
}

.text-none:hover,
.text-none:focus {
	color: #ffffff !important;
}

.logo-imgs {
	width: 150px;
	height: auto;
	margin: 0 15px;
}

.logo-bottom-text {
	text-align: center;
}

.logo-bottom-text p {
	color: #fff;
	margin: 0;
}

.logo-bottom-text a {
	font-size: 40px;
	font-weight: bold;
	font-style: italic;
	font-family: Brush Script MT;
	text-decoration: none !important;
	color: #ffffff;
}
.logo-bottom-text a sup {
	font-style: normal;
}

.bottom-name-top {
	color: #ffffff !important;
	font-family: 'Montserrat', sans-serif;
}

.bottom-name-top:hover b {
	text-decoration: none !important
}

.logo-bottom-text a:hover,
.logo-bottom-text a:focus {
	color: #ffffff !important;
}

.logo {
	width: 100%;
}

.divimg {
	width: 100%;
	height: 300px
}

.box {
	box-shadow: 10px 10px 40px #808080;
}

.boxT {
	padding: 10px;
}

.btitlle {
	text-align: center;
	color: #000;
	font-size: 25px;
	/*background: #31689b;*/
	padding: 10px;
	font-family: louis_bold;
	font-weight: bold;
	border-radius: 5px;
}

.boxA {
	/*box-shadow: 0px 0px 10px #000;*/
	border-radius: 20px;
	padding: 10px
}

.babout {
	font-size: 15px;
	text-align: justify;
	font-family: louis_bold;
	color: #000;
	text-align: center;
}

.aboutcol {
	position: fixed;
	bottom: 0;
}


.box:hover {
	animation-name: pulse;
	animation-duration: 4s;
	animation-timing-function: ease-out;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-fill-mode: none;
	animation-play-state: running;
	-webkit-backface-visibility: visible;
	backface-visibility: visible;
}

@-webkit-keyframes pulse {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	50% {
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes pulse {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	50% {
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}


/***** Product-page *****/
.product-wrapper .head p{
	color: #020e49;
}
.yes-marg {
    margin-top: 30px;
}
.men-pro-item {
    position: relative;
    box-shadow: 0px 0px 15px 0px #8e8c8c;
    padding-bottom: 50px;
}
.bracelets-item .men-pro-item{
	border: 5px solid transparent;
}
.men-thumb-item {
    position: relative;
	opacity: 1;
    visibility: visible;
}
.men-thumb-item::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    opacity: 0;
    z-index: 9;
    visibility: hidden;
    transition: all 0.5s ease-out 0s;
}
.bracelets-item img {
    max-width: 100%;
    cursor: pointer;
}
.men-thumb-item .pro-image-front {
    opacity: 1;
    visibility: visible;
    object-fit: contain;
}
.men-thumb-item img {
    transition: all 0.5s ease-out 0s;
    padding: 26px 20px 19px 12px;
}
.pro-image-back {
    transform: rotateY(180deg);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.item-info-product {
    text-align: center;
    margin: 20px 0 0;
}
.info-product-price {
    margin: 15px 0;
}
.info-product-price span {
    color: #000;
    font-size: 23px;
}
.choose-bracelet {
    color: #fff;
    font-size: 16px;
    padding: 3px 14px;
    text-decoration: none;
    border: none;
}
.hvr-outline-out {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    backface-visibility: hidden;
    position: relative;
    background: #020e49;
}
.hvr-outline-out:before {
    content: '';
    position: absolute;
    border: #020e49 solid 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-property: top, right, bottom, left;
    transition-property: top, right, bottom, left;
}
.hvr-outline-out:hover:before, .hvr-outline-out:focus:before, .hvr-outline-out:active:before {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
}
.text_margin {
    margin-top: 10px;
}
.bracelet-wrapper .select-size .head h3 {
    margin-bottom: 30px;
}
.head h3 {
    color: #020e49;
    text-align: center;
    font-size: 35px;
}
.bracelet-size {
    max-width: 699px;
    margin: 0 auto;
    margin-bottom: 40px;
}
.bracelet-size select{
	margin: 0 auto;
}
.img-style{
	margin-left: 4rem;
}
.bracelets-text{
	font-size: 21px;
	color: #fff;
	font-weight: bold;
}
.bracelet-size h4, .size-chart h4 {
    color: #020e49;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}
.select-size {
    text-align: center;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 15px;
	    color: #020e49;
    border: 1px solid #020e49;
    border-radius: 2px;
    margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}
select:focus,select:focus-visible{
	background-color: transparent;
	border: 1px solid #020e49;
	outline: none;
}
#w-size *{
	border-bottom: 1px solid black;
}
.pay-btn {
    margin: 20px 0;
    background-color: #010724d1 !important;
    border: 1px solid rgba(0,0,0,0.8) !important;
    color: white;
    height: 45px;
    width: 138px;
}

.prod-det-item {
    padding-top: 15px;
}
.prod-det-item {
    border-bottom: 1px solid #ddd;
}
.prod-header {
    width: 100%;
    display: flex;
}
.prod-header .prod-image {
    width: 100px;
    flex: 0 0 100px;
}
#cart-dtl img {
    max-height: 100px;
    max-width: 100%;
}
.prod-header .pord-name {
    padding-left: 15px;
}.pord-name h5 {
    font-size: 18px;
    margin-bottom: 5px;
    margin: 0;
    color: #6c757d;
    font-weight: bold;
}
.pord-name .prd-price {
    font-weight: bold;
    color: #000;
    margin-bottom: 8px;
    font-size: 14px;
}
.charity-input p {
    font-size: 18px;
    color: #020e49;
    margin-bottom: 5px;
}
.charity-input input {
    max-width: 500px;
    height: 50px;
    margin: 0 auto;
    font-size: 16px;
    background-color: #010724d1 !important;
    border: 1px solid rgba(0,0,0,0.8) !important;
    color: #eee;
}
.charity-input .form-control:focus{
	color: #eee;
}
.cart-middle {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 15px 0 15px;
}
.cart-middle .close-card {
    width: 115px;
    flex: 0 0 115px;
}
.close-card {
    font-size: 0.8rem;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 15px;
    font-weight: bold;
}
.cart-middle .close-card button {
    background: none;
    border: none;
    color: #000;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 14px;
}
.quantity {
    border: 1px solid #ddd;
    padding: 7px 0px;
    font-size: 1.1rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    white-space: nowrap;
}
.cart-middle .quantity {
    white-space: normal;
    padding: 2px 0;
}
.quantity button {
    font-size: 16px;
    font-size: 22px;
    font-weight: bold;
    color: #222;
    background: none;
    border: none;
}
.cart-middle .quantity button {
    width: 60px;
}
.inc {
    border: none;
    padding: 0 0px 0 7.5px;
    margin-bottom: 0vh;
    outline: none;
    width: auto;
    text-align: center;
    max-width: 60%;
    background-color: transparent;
    font-size: 18px;
    font-weight: bold;
}
.cart-middle .inc {
    width: 100%;
    max-width: 100%;
}
.card-footer{
	background-color: transparent;
	border-top: none;
}
.card-footer .btn-checkout {
    background-color: #000;
    border-color: #000;
    color: white;
    width: 180px;
    font-size: 14px;
    margin: auto;
    padding: 1vh;
    border-radius: 0;
    display: inline-block;
    margin: 0 15px;
}
.card-footer .btn-checkout {
    max-width: 165px;
	width: 100%;
    margin: 0 auto;
}
/***** End Product-page *****/
		
/***** Payment Page *****/
.checkout-wrapper {
    width: 100%;
    padding: 50px 0;
    background: #ddd;
}
.checkout-row {
    background: #fff;
    padding: 40px;
    box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.check-left {
    border-right: 1px solid #ddd;
    padding-right: 45px;
    min-height: 60vh;
}
.check-left h4 {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 22px;
}
.ship-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.ship-row .ship-col2 {
    width: 48%;
}
.ship-input {
    width: 100%;
    margin-bottom: 15px;
}
.ship-input input, .ship-input select {
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
}
.ship-check {
    width: 100%;
    margin: 8px 0 25px;
}
.ship-check label {
    font-weight: normal;
    cursor: pointer;
}
.ship-check input {
    margin-right: 10px;
    vertical-align: -5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.ship-btn {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.btn-check {
	position: relative;
	cursor: pointer;
    background: #000;
    color: #fff;
    border: #000;
    padding: 12px 30px;
    font-size: 15px;
}
.ship-btn a {
    color: #000;
    text-decoration: underline;
    margin-left: 20px;
}
.check-right {
    padding-left: 15px;
}
.prod-item {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.prod-item .prod-img {
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #ddd;
}
.prod-item .prod-img img {
    max-width: 70px;
}
.prod-item .prod-info {
    padding-left: 15px;
    width: 100%;
}
.prod-item .prod-info h3 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0px;
}
.prod-item .prod-info span {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
}
.qty-price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.prod-item .qty-price span {
    margin: 0;
}
.prod-item .prod-info h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}
.ship-total {
    width: 100%;
    margin-top: 15px;
}
.total-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
}
.ship-total span {
    font-size: 16px;
    color: #777;
}
.ship-total p {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    color: #777;
}
.total-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
}
.total-final {
    width: 100%;
    display: flex;
    border-top: 1px solid #ddd;
    padding-top: 15px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
}
.ship-total .total-final span {
    font-size: 20px;
    color: #111;
}
.ship-total p {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    color: #777;
}
/***** End Payment Page *****/

/***** about Us *****/

.about-container .head-lg{
	height: 100px;
	width: 100%;
}
.about-container h2{
	color: #337ab7;
}
.about-container .mission-text{
	font-weight: 700;
}
.about-container .mandFirst{
	text-align: start;
}
/***** end about Us *****/
@media (max-width:991px) and (min-width:200px) {
	.aboutcol {
		position: static;
		margin-top: 3%;
	}
}

@media (max-height:900px) and (min-height:200px) {
	.aboutcol {
		position: static;
		margin-top: 5%;
	}
}

.imgs {
	height: 150px;
	width: 100%;
}

.headup p {
	text-align: center;
	font-size: 17px;
}

.mandDescp {
	font-size: 23px;
	text-align: center;
	/*color: #7b7373;*/
	color: #000;
}

.mandFirst {
	font-size: 24px;
	text-align: center;
	/*color: #7b7373;*/
	color: #000;

}

.btnimg {
	background: #31689b;
	color: #fff;
}

.headImg {
	height: 100%;
	border: 1px solid #000;
	padding: 10px;
	border-radius: 10px 0px 10px 0px;
}

.Imght {
	height: 700px !important;
}

.carousel-caption h1 {
	font-size: 4em;
}

.carousel-caption h1 a {
	color: #fff;
	text-decoration: none;
}

.boxT p {
	text-align: center;
	font-size: 20px;
}


@media (max-width:600px) and (min-width:200px) {
	.dtls {
		margin-top: 25px !important;
	}

	.carousel-caption h1 {
		font-size: 3em;
	}

	.logo_title {
		font-size: 3em !important;
	}
}

@media (max-width:400px) and (min-width:200px) {
	.logo_title {
		font-size: 2em !important;
	}

	.tshirtimg {}
}

@media (max-width:350px) and (min-width:200px) {


	.tshirtimg {
		width: 100% !important;
	}
}

.tshirtimg {
	width: 350px;
}

.menudiv {
	background: #337ab7;
	height: 80px
}

.menudiv ul {
	list-style: none;
}

.menudiv ul li {
	float: left;
	padding-left: 30px;
	line-height: 6em;
}

.menudiv ul li a {
	color: #fff !important;
	font-size: 1.5em;
	text-decoration: none;
	transition: all 0.5s;
}

.menudiv ul li a:hover {
	color: #000 !important;
}

.logo_title {
	font-family: safinaScript !important;
	font-size: 6em;
	color: #23527c;
	-webkit-text-stroke: 0.1px #23527c;
	/*-moz-text-stroke: 0.1px red;*/
	letter-spacing: 5px;
}

.tagline {
	font-family: dagoBold !important;
	font-size: 3em;
	color: #23527c;
	font-weight: bold;
	padding-left: 20px;
	letter-spacing: 25px;
}

.learn_cls {
	padding: 8px 24px;
	border: 1px solid #000;
	color: #000;
	font-size: 18px;
	display: table;
	margin: 10px auto;
	border-radius: 8px;
}

.otherLearn_cls {
	display: inline-block;
}

.learn_cls1 {
	padding: 8px 18px;
	border: 1px solid #000;
	color: #000;
	display: table;
	margin: 10px auto;
	border-radius: 8px;
}


.learn_cls:hover {
	color: #337ab7;
	border: 1px dotted #337ab7;
}

.learn_cls1:hover {
	color: #337ab7;
	border: 1px dotted #337ab7;
}

.learn_cls_res {
	border: 1px solid #000;
	color: #000;
}

.learn_cls_res:hover {
	color: #000;
}

.cont_Mg {
	margin: 0px 10%;
}

@media (max-width:1700px) and (min-width:200px) {
	.cont_Mg {
		margin: 0px;
	}
}

.blue_clr {
	color: #337ab7;
}

.white_clr {
	color: #fff !important;
}

.mandala_log_sides p sup {
	font-size: 18px;
}

.intro_img {
	height: auto;
	width: 100%;
	border-radius: 10px;
	box-shadow: 0px 0px 20px #808080;
}

.side_image {
	/*background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 400px; */

}

.side_image img {
	/* height: 400px;
	width:400px;
	border-radius: 10px;
	box-shadow: 10px 10px 20px #808080; 
	object-fit: cover; */
}

.side_image video {
	height: 400px;
	width: 400px;
	border-radius: 10px;
	box-shadow: 10px 10px 20px #808080;
	background-color: #000;
}

.side_cont div.label {
	top: 5px;
	color: #337ab7;
	font-size: 25px;
	text-align: center;
	position: relative;
	font-family: louis_bold !important;
}

@media (max-width:330px) and (min-width:100px) {
	.side_cont div.label {
		font-size: 15px;
	}

	.learn_cls {
		font-size: 12px;
	}

	.learn_cls1 {
		font-size: 12px;
	}

}

@media (max-width:490px) and (min-width:100px) {
	.intro_img {
		height: 300px;
	}

	.mandala_log_sides p sup {
		font-size: 15px;
	}
}

@media (max-width:990px) and (min-width:100px) {

	.mandala_log_sides p sup {
		font-size: 17px;
	}
}

@media (max-width:400px) and (min-width:100px) {
	h1 {
		font-size: 20px;
	}
}

@media (max-width:1218px) and (min-width:100px) {

	.side_image img,
	.side_image video {
		width: 100%;
		height: 230px;
	}

	.mandala_log_sides p sup {
		font-size: 17px;
	}
}

@media (min-width:1600px) {

	.side_image img,
	.side_image video {
		width: 100%;
	}

}

@media (max-width:1599px) and (min-width:1219px) {

	.side_image img,
	.side_image video {
		width: 100%;
		height: 260px;
	}

}


.modal_width {
	width: 50%;
	margin: 5% auto;
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
}

.modal_width.modal-video {
	width: 50%;
	max-width: 750px;
}


.md2 {
	width: 45% !important;

}

@media (max-width:1700px) and (min-width:1024px) {
	.modal_width {
		width: 60%;
	}

	.md1 {
		width: 50% !important;
		/*height: 500px;*/
	}

	.md2 {
		width: 45% !important;
		/*height: 500px;*/
	}
}

@media (max-width:1920px) and (min-width: 1651px) {
	.restxt {
		margin-top: 296px !important;

	}
}

@media (max-width:1423px) and (min-width: 1258px) {
	.restxt {
		margin-bottom: 6px !important;

	}
}

@media (max-width:1920px) and (min-width: 200px) {

	.md1 {
		width: 50% !important;
		/*height: 504px;*/
	}

	.md2 {
		width: 45% !important;
		/*height: 495px;*/
	}

	/*.restxt{
		margin-top:296px !important;
		
	}*/
	/*.modal_width{
		width:40% !important;
		height:495px !important;
	}*/
}

@media (max-width:1200px) and (min-width:1024px) {
	.modal_width {
		width: 80%;
	}
}

.modal_white {
	/* background: url('./images/2_tile.jpg./images/new_contact_1.png'); */
	background: #FDFBE1;
	color: #000;
	background-repeat: no-repeat;
	background-size: cover;
	border: 6px solid #fffbcb;
}

.close {
	line-height: 0 !important;
	font-size: 4em !important;
	color: #fff !important;
}

.vdodiv {
	margin: 0px;
}

.video-wrapper {
	position: relative;
}

.video-wrapper #playvdo1 {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto !important;
}

.how-video {

	max-height: 380px;
	height: auto !important;
}

#howWorkModal .row {
	display: flex;
	align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}
	
	#contModal .modal_width {
		width: 94%;
		max-width: 990px;
	}
	
	@media (max-width:1024px) and (min-width:200px) {
		.vdodiv {
			margin: 0px;
		}
	}
	
	.vdotxt {
	
		font-size: 15px;
		text-align: justify;
	
	}
	
	.vdoPlay {
		/*margin: 40%;*/
		margin-top: -255px;
	}
	
	*::-webkit-media-controls-panel {
		background-color: #000 !important;
	}
	
	.whynowtxt {
		font-size: 16px;
		color: #020e49;
		font-weight: bold !important;
	
	}
	
	.ourmissiontxt {
		font-size: 18px;
	}
	
	.imgbag {
		height: 125px;
		width: 100%;
	}
	
	
	@media (min-width: 291px) and (max-width:700px) {
		.imgbag {
	
			width: 50% !important;
		}
	}
	
	@media (min-width: 701px) and (max-width:866px) {
		.imgbag {
	
			width: 60% !important;
		}
	}
	
	@media (min-width: 867px) and (max-width:991px) {
		.imgbag {
	
			width: 50% !important;
		}
	}
	
	.prSeemore {
		border: 1px solid #fff;
		color: #fff;
		font-size: 13px;
	}
	
	
	
	.res_blank {
		margin-top: 10px;
	}
	
	.res_text {
		text-align: center;
		border-radius: 0 0 6px 6px;
	}
	
	.res_text_padding {
		padding: 0px 5px 0px 5px;
	}
	
	@media (min-width:1900px) {
		.md1 {
			width: 40% !important;
		}
	
		.md2 {
			width: 40% !important;
		}
	}
	
	
	.ourgoal_text {
		background: #280a00;
		text-align: center;
		border-radius: 0 0 6px 6px;
		margin-bottom: -10px;
	}
	
	.ourgoal_text_padding {
		padding: 0px 5px 8px 5px;
		font-size: 22px;
	}
	
	.our_text {
		font-style: italic;
	}
	
	.restxt {
		margin-top: 100px;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 10px;
		margin-bottom: -15px;
	}
	
	.restxt p {
		color: #fff;
		font-size: 18px;
		margin-top: 250px;
		padding: 5px;
		line-height: 1.5em;
	}
	
	.contDiv {
		background: #337ab7;
		border: 1px solid #fff;
		border-radius: 10px;
		box-shadow: 0px 0px 40px #808080;
	}
	
	.contDiv p {
		padding: 10px;
		color: #fff;
		text-align: center;
		font-size: 20px;
		vertical-align: middle;
		cursor: pointer;
		margin: 0px !important;
	}
	
	@media (max-width:700px) and (min-width:100px) {
		.modal_width {
			width: 90% !important;
		}
	}
	
	.adrcont {
		background-color: #010724d1;
		border-radius: 5px;
		height: auto;
		font-family: louis_bold !important;
		font-size: 15px;
		color: #fff;
		border: 1px solid rgba(0, 0, 0, 0.8);
		padding-bottom: 20px;
	}
	
	.adrcont h3 {
		color: #31689b;
		border-bottom: 1px solid #31689b;
		margin-bottom: 15px;
		clear: both;
	}
	
	.form-control {
		background-color: #010724d1;
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.8);
		font-size: 15px;
		color: #fff;
		font-family: louis_bold !important;
	}
	
	/*::-webkit-input-placeholder {
			  color: black !important;
			}
			*/
	.imgbg {
		/* background:url(./images/2_tile.jpg./images/sky_resize.jpg); */
		background-color: #353E5B;
		background-size: 100% 100%;
	}
	
	.contcrow {
		margin-top: 97px;
		position: absolute;
	
	}
	
	.contUs-logo a {
		border-radius: 50%;
		height: 36px;
		width: 36px;
		line-height: 36px;
		display: inline-block;
		padding: 0;
		text-align: center;
		background: white;
		border: box;
		margin: 2px;
	}
	
	.contUs-logo a .fa {
		font-size: 18px;
		line-height: 36px;
	}
	
	@media (max-width:991px) and (min-width:200px) {
		.contcrow {
			margin-top: 10px;
			position: inherit !important;
		}
	
		.mb-16 {
			margin-bottom: 16px;
		}
	
		#contModal .modal_width {
			max-width: 550px;
		}
	}
	
	@media (max-width:991px) and (min-width:200px) {
		.contcrow {
			width: 37% !important;
			position: inherit !important;
		}
	
		.contcrow p {
			font-size: 12px !important;
		}
	}
	
	.why-img {
		height: 230px;
		/*width:100%;*/
	}
	
	.our-mission {
		height: 35%;
		border: 6px solid #32689B;
	}
	
	@media (max-width:1000px) and (min-width:200px) {
		.why-img {
			height: 115px !important;
		}
	
		.our-mission {
			height: 28%;
		}
	}
	
	.why-ft {
		margin-top: 30px;
	}
	
	.why-see {
		width: 100%;
		margin-top: 10px;
	}
	
	.head-logo {
		height: 220px;
		width: 220px;
	}
	
	.head-logo-ft {
		height: 200px;
		width: 200px;
	}
	
	.head-logo1 {
		height: 60px;
		width: 100%;
	}
	
	.head-logo-show {
		height: 35px;
		width: 80%;
	}
	
	.tile_2_span,
	.tile_4_span {
		font-weight: normal;
		font-family: "louis_bold" !important;
	
	}
	
	@media (min-device-width:200px) and (max-device-width:240px) {
		.head-logo {
			width: 100% !important;
		}
	
		.mandDescp {
			font-size: 14px;
		}
	
		.mandFirst {
			font-size: 15px;
		}
	}
	
	@media (min-device-width:767px) and (max-device-width:991px) {
	
		.head-logo1,
		.head-logo-show {
			width: 50% !important;
		}
	
	}
	
	@media (min-device-width:547px) and (max-device-width:766px) {
	
		.head-logo1,
		.head-logo-show {
			width: 70% !important;
		}
	
	}
	
	@media only screen and (max-width: 1600px) {
		.side_image img.banner-img {
			height: 330px;
		}
	}
	
	@media only screen and (max-width: 767px) {
		p.h3.mt {
			margin-top: 2rem;
			margin-bottom: 0;
		}
	
		.mt {
			margin-top: 2rem;
		}
	
		.enthusiasm {
			margin-bottom: 3rem;
		}
	
		.head-logo {
			width: 200px;
			height: 200px;
		}
	
		.head-logo-new {
			width: 100% !important;
			height: 115px !important;
		}
	
		.content-div {
			margin-bottom: 10px;
		}
	
		.tile_2_span {
			list-style-type: none;
			display: list-item;
	
		}
	
	}
	
	@media screen and (max-width:519px) {
		p.h3.mt {
			line-height: 23px;
		}
	
		.level-image,
		.ring .row {
		flex-direction: column;
			align-items: center;
		}
		.col-sm-4,
		.col-md-4 {
			width: 326.23px !important;
			padding: 0;
		}
		}
		
		@media only screen and (max-width: 1024px) {
			.head-logo-show {
				height: 25px;
				width: 80%;
			}
		
			.head-logo1 {
				height: 45px;
				width: 100%;
			}
		
			.head-logo-ft {
				height: 150px;
				width: 150px;
			}
		
			.tile_2_span {
				list-style-type: none;
				display: list-item;
		
			}
		
			.img-1,
			.img-3 {
				margin-top: 0px;
			}
		
		
		}
		
		@media only screen and (min-width: 1024px) and (max-width: 1700px) {
			.tile_2_span {
				list-style-type: none;
				display: list-item;
		
			}
		
			.tile_4_span {
				list-style-type: none;
				display: list-item;
		
			}
		
			.img-1,
			.img-3 {
				margin-top: 0px;
			}
		}
		
		@media only screen and (min-device-width:768px) and (max-device-width:1024px) {
			.head-logo-new {
				width: 50% !important;
				height: 100px !important;
			}
		}
		
		.how-logo {
			height: 150px;
			width: 150px;
		}
		
		@media (min-device-width:200px) and (max-device-width:991px) {
			.how-logo {
				margin-top: 20px !important;
				height: 100px;
				width: 100px;
			}
		
			.whynowtxt,
			.ourmissiontxt {
				font-size: 15px !important;
			}
		
			.vdotxt {
				font-size: 13px !important;
			}
		
			.learn_cls {
				font-size: 14px !important;
			}
		
		}
		
		.head-logo-new {
			width: 35%;
			height: 130px;
		}
		
		.content-div {
			background-color: #FDFBE1;
			width: auto;
		
			box-shadow: 10px 10px 20px #FFFBCB;
			border-radius: 10px;
			padding: 5%;
			text-align: center;
			font-size: 22px;
		}
		
		.content-div p {
			text-align: left;
		}
		
		.m-t-20 {
			margin-top: 20px;
		}
		
		@media (min-device-width:768px) and (max-device-width:1024px) {
			.vdoPlay {
		
				margin-top: -360px !important;
			}
		}
		
		@media (min-device-width:768px) {
			.vdoPlay {
		
				/*margin-top: -273px !important;*/
				margin-top: -170px !important;
			}
		}
		
		@media (max-width:359px) {
			.col-sm-4 {
				width: 278.23px !important;
			}
		}
		
		@media (min-width: 1600px) {
			.vdoPlay {
				margin-top: -235px !important;
			}
		}
		
		@media (min-device-width:200px) and (max-device-width:767px) {
			.vdoPlay {
		
				/*margin-top: -220px !important;*/
				margin-top: -176px !important;
			}
		
			.other_label {
				padding: 0px !important;
			}
		
			.m-t-20 {
				margin-top: 10px;
			}
		}
		
		@media (min-device-width:768px) {
			.mandDes_span {
				display: list-item;
				list-style-type: none;
			}
		
			.how-video {
				height: 50%;
			}
		}
		
		.patch-cls {
			height: 30px;
		}
		
		.contDiv-btn {
			font-family: 'Lato', sans-serif;
			background: #337ab7;
			border: 1px solid #fff;
			border-radius: 10px;
			box-shadow: 0px 0px 40px #808080;
			color: #fff;
			padding: 12px 35px;
			font-size: 20px;
		}
		
		.contDiv-btn:hover {
			text-decoration: none;
			color: #fff;
			border: 1px solid #fff;
		}
		
		.footer-bottom {
			text-align: center;
			margin-top: 15px;
			margin-bottom: 15px;
		}
		
		.footer-bottom .ic_class {
			display: inline-block;
		}
		
		.footer-bottom ul li {
			display: inline-block;
			margin: 0 4px;
		}
		
		.footer-bottom .ic_class a {
			width: 36px;
			height: 36px;
			line-height: 36px;
			display: inline-block;
			text-align: center;
			margin: 0;
			background: #fff;
			border-radius: 50%;
			color: #23527c !important;
			font-size: 23px;
		}
		
		.footer-bottom .ic_class a .fa {
			margin: 0;
			line-height: 36px;
			transition: 1s all;
			-webkit-transition: 1s all;
			-moz-transition: 1s all;
			-o-transition: 1s all;
			-ms-transition: 1s all;
		}
		
		.footer-bottom .ic_class a:hover .fa {
			transform: rotateY(360deg);
		}
		
		.loader-wrapper {
			width: 100%;
			text-align: center;
			display: none;
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 999999999;
			background: rgba(255, 255, 255, 0.8);
		}
		
		.loader-wrapper img {
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -32px 0 0 -32px;
		}
		
		@media (max-device-width:767px) {
			.contDiv-btn {
				padding: 12px 25px;
			}
		
			.otherLearn_cls {
				display: table;
			}
		}
		
		a:focus {
			color: #ffffff !important;
			text-decoration: none !important;
		}
		
		.side-left {
			min-width: 150px;
			text-align: right;
			display: inline-block;
		}
		
		.mandala_log_sides.side-left {
			min-width: 282px;
		}
		
		@media only screen and (max-width: 1279px) {
		
			.buttom_log_sides .h1,
			.logo-bottom-text a {
				font-size: 28px;
			}
		
			.mandala_log_sides.side-left {
				min-width: 223px;
			}
		
		}
		
		@media only screen and (max-width: 1024px) {
		
			.buttom_log_sides .h1,
			.logo-bottom-text a {
				font-size: 26px;
			}
		
			.mandala_log_sides.side-left {
				min-width: 209px;
			}
		
			.side-left {
				min-width: 115px;
			}
		
		}
		
		.row.buttom_log_sides {
			display: block;
			margin: 0;
		}
		
		.mandala_log_sides,
		.logo-img-col {
			display: inline-block;
		}
		
		@media only screen and (max-width: 767px) {
			.mandala_log_sides.side-left {
				min-width: 100%;
				text-align: center;
			}
		
			.font-web-b {
				font-family: Baskerville BT Bold;
				font-size: 21px;
			}
		
			.buttom_log_sides .h1,
			.logo-bottom-text a {
				font-size: 24px;
			}
		
			.logo-imgs {
				width: 120px;
				margin: 10px 5px 0
			}
		
			.side-left {
				min-width: 80px;
				margin-right: 10px;
			}
		
			.buttom_log_sides {
				flex-wrap: wrap;
			}
		
			.logo-img-col {
				order: 1;
			}
		
			.h1 {
				font-size: 25px !important;
			}
		
			.mandDescp {
				font-size: 16px !important;
			}
		
			.mandDescp.mandFirst {
				font-size: 18px !important;
			}
		
			.h2 {
				font-size: 20px !important;
			}
		
			.h3 {
				font-size: 16px !important;
			}
		
			.side_cont div.label {
				font-size: 20px !important;
			}
		
			.content-div {
				font-size: 16px !important;
			}
		
			.side-left {
				order: 0;
			}
		
			.logo-img-col {
				width: 100%;
				order: -1;
				margin-bottom: 8px;
			}
		
			.mandDescp.blue_clr {
				font-size: 20px !important;
			}
		}
		
		/*cart css*/
		
		.card {
			margin: auto;
			max-width: 950px;
			width: 90%;
			box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			border-radius: 1rem;
			border: transparent
		}
		
		.text-muted {
			font-size: 1.2rem;
		}
		
		.title {
			margin-bottom: 5vh
		}
		
		.card .row {
			display: flex;
			flex-wrap: wrap;
		}
		
		#code {
			background-image: linear-gradient(to left, rgba(255, 255, 255, 0.253), rgba(255, 255, 255, 0.185)), url("https://img.icons8.com/small/16/000000/long-arrow-right.png");
			background-repeat: no-repeat;
			background-position-x: 95%;
			background-position-y: center
		}
		
		.quantity {
			border: 1px solid #ddd;
			padding: 7px 0px;
			font-size: 1.1rem;
			box-sizing: border-box;
			display: flex;
			justify-content: space-evenly;
			white-space: nowrap;
		}
		
		.quantity1 {
			display: flex;
			margin: auto;
			padding-right: 30px;
		}
		
		.dot {
			display: inline-block;
			margin: 0 18px;
			content: '';
			height: 3px;
			width: 3px;
			border-radius: 100%;
			background: currentColor;
		}
		
		.btn {
			display: flex;
			align-items: center;
		justify-content: center;
	margin-top: 18px;
	}
	
	.inc {
		border: none;
		padding: 0 0px 0 7.5px;
		margin-bottom: 0vh;
		outline: none;
		width: auto;
		text-align: center;
		max-width: 60%;
		background-color: transparent;
		font-size: 18px;
		font-weight: bold;
	}
	
	.quantity button {
		font-size: 16px;
		font-size: 22px;
		font-weight: bold;
		color: #222;
		background: none;
		border: none;
	}
	
	#cart-box img {
		max-height: 100px;
		max-width: 100%;
	}
	
	#cart-box .row {
		align-items: center;
		justify-content: space-between;
	}
	
	#cart-dtl img {
		max-height: 100px;
		max-width: 100%;
	}
	
	#cart-dtl .row {
		align-items: center;
		justify-content: space-between;
	}
	
	.card-footer h6 {
		font-size: 16px;
		text-align: left;
	}
	
	.card .card-footer p {
		font-size: 14px;
		text-align: left;
		font-weight: bold;
	}
	
	.card .cart {
		padding: 25px;
	}
	
	.card .cart h4 {
		font-size: 24px;
		margin-top: 0;
		text-align: center;
		width: 100%;
	}
	
	.close-card {
		font-size: 0.8rem;
		text-transform: uppercase;
		text-decoration: underline;
		font-size: 15px;
		font-weight: bold;
	}
	
	.card-footer .btn-checkout {
		background-color: #000;
		border-color: #000;
		color: white;
		width: 30%;
			font-size: 14px;
			margin: auto;
			padding: 1vh;
			border-radius: 0;
			display: inline-block;
		}
		
		.pord-name h5 {
			color: #6c757d;
			font-weight: bold;
			font-size: 20px;
			margin: 0;
		}
		
		.card .pord-name p {
			text-align: left;
			font-weight: 600;
			font-size: 14px;
		}
		
		#msgBox {
			max-width: 100%;
			white-space: normal;
		}
		
		.card-footer {
			text-align: center;
		}
		
		
		@media only screen and (max-width: 767px) {
		
			#cart-box .col-md-2.col-sm-2 {
				width: 100%;
				text-align: center;
			}
		
			#cart-box .col-xxl-4 {
				width: 100%;
			}
		
			#cart-box .quantity {
				margin-top: 15px;
				margin-bottom: 15px;
			}
		
			#cart-dtl .col-md-2.col-sm-2 {
				width: 100%;
				text-align: center;
			}
		
			#cart-dtl .col-xxl-4 {
				width: 100%;
			}
		
			#cart-dtl .quantity {
				margin-top: 15px;
				margin-bottom: 15px;
			}
		
			
			.card .pord-name p {
				text-align: center;
			}
		
			.card-footer .btn-checkout {
				width: auto;
			}
		
			.card .cart {
				width: 100%;
				padding: 20px 15px;
			}
		
			#cart-box .d-flex.justify-content-center.col-8 {
				width: 100%;
			}
		
			#cart-dtl .d-flex.justify-content-center.col-8 {
				width: 100%;
			}
		}
		
		/*.header {border-bottom: 1px solid #dcdcdc;font-family: 'Lato', sans-serif; position: relative; z-index: 2;}
						.header_wrapper ul {display: flex;justify-content: space-between;align-items: center; padding: 0; margin: 0;}
						.header ul li {display: inline-block;width: 33%;text-align: center;color: #848484;font-size: 16px;padding: 12px 0;}
						.header ul li:nth-child(1) {text-align: left;}
						.header ul li:nth-child(2) {border-left: 1px solid #dcdcdc;border-right: 1px solid #dcdcdc;}*/
		
		.header {
			border-top: 1px solid #000;
			font-family: 'Lato', sans-serif;
			position: relative;
			z-index: 2;
			padding: 12px 0;
			margin-top: 15px;
		}
		
		.header_wrapper ul {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			margin: 0 !important;
		}
		
		.header ul li {
			display: inline-block;
			width: 33%;
			text-align: center;
			color: #848484;
			font-size: 16px;
			padding: 12px 0;
		}
		
		.header ul li:nth-child(1) {
			text-align: left;
		}
		
		.header ul li:nth-child(2) {
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
		}
		
		.header .head-info {
			color: #ddd;
		}
		
		.header .header-login a {
			color: #eee;
		}
		
		.header .main-nav {
			width: 50%;
			max-width: 430px;
			display: flex;
			justify-content: center;
		}
		
		.header .main-nav ul li {
			width: auto;
			padding: 0 15px;
			position: relative;
		}
		
		.header .main-nav ul li ul {
			position: absolute;
			top: 100%;
			left: 0;
			display: none;
			background: #212942;
			width: 300px;
		}
		
		.header .main-nav ul li ul li {
			width: 100%;
			text-align: left;
			border: none;
		}
		
		.header .main-nav ul li ul li a {
			padding: 5px;
			display: block;
			font-weight: normal;
			font-size: 16px;
		}
		
		.header .main-nav ul li:hover ul {
			display: block;
			padding: 15px 10px;
			box-shadow: 0px 10px 15px rgb(0 0 0 / 8%);
		}
		
		.header .main-nav ul li a {
			color: #fff;
			font-weight: bold;
			font-size: 17px;
			text-decoration: none !important;
		}
		
		.header .main-nav ul li a .fa {
			font-weight: bold;
			margin-left: 5px;
			font-size: 20px;
			vertical-align: -2px;
		}
		
		.header .main-nav ul li a:hover,
		.header .main-nav ul li a:focus {
			color: #aaa !important;
		}
		
		.header .header-login {
			width: 33%;
			text-align: right;
			color: #bbb;
		}
		
		.header .header-login a {
			margin-left: 10px;
			margin-right: 10px;
			text-decoration: none !important;
		}
		
		.header .header-login a:hover,
		.header .header-login a:focus {
			color: #aaa !important;
		}
		
		.about-logo {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
		}
		
		.about-logo img {
			display: block;
			margin: 0 auto;
		}
		
		.about-logo .ab-logo-icon {
			width: 100px;
			height: auto;
		}
		
		.about-logo .ab-logo-text {
			width: 230px;
			height: auto;
		}
		
		.demo-video .how-video {
			max-height: 550px;
			width: auto !important;
			margin: 0 auto;
			display: block;
			max-width: 100%;
		}
		
		.menu-row {
			display: flex;
			justify-content: space-between;
		}
		
		.menu-row .head-info {
			width: auto;
		}
		
		.header .header-login {
			width: auto;
			order: 3;
		}
		
		.header {
			position: relative;
			z-index: 99;
		}
		
		.about-wrapper .mandFirst {
			text-align: justify;
		}
		
		.about-wrapper .mandDescp.about-logo-wrapper {
			text-align: center;
		}
		
		.about-wrapper .mandDescp.about-logo-wrapper .about-logo {
			margin-right: 0;
		}
		
		.item-icon .fa {
			float: left;
			display: block;
			margin-top: 5px;
		}
		
		.item-icon span {
			display: block;
			margin-left: 25px;
			word-break: break-word;
		}
		
		
		@media only screen and (max-width: 1099px) {
		
			.menu-header.header .header-login {
				width: auto;
			}
		
			.menu-header.header .head-info {
				font-size: 15px;
				width: auto;
			}
		
			.header .main-nav ul li a {
				font-size: 15px;
			}
		
			.header .header-login a {
				font-size: 15px;
			}
		
			.menu-row .head-info {
				font-size: 14px;
			}
		}
		
		@media only screen and (max-width: 991px) {
		
			.menu-row {
				flex-wrap: wrap;
			}
		
			.menu-header.header .head-info {
				width: 50%;
			}
		
			.header .main-nav {
				width: 100%;
				max-width: 100%;
				justify-content: center;
				order: 3;
				margin-top: 20px;
			}
		
			.menu-header.header .header-login {
				width: 50%;
				order: 2;
			}
		
			input.pull-right.btn-block.btnimg {
				margin-right: 0 !important;
			}
		
			#contModal .col-md-6.col-md-offset-1 {
				margin-left: 0 !important;
				max-width: 510px;
				margin: 0 auto !important;
				display: block;
			}
		
			.adrcont {
				width: 100%;
				max-width: 450px;
				margin: 0 auto;
			}
		
			.header-right.footer-bottom {
				width: 100%;
				padding: 0;
			}
		
			p.h3.mt {
				margin-top: 2rem;
				margin-bottom: 0;
			}
		
			.col-sm-4 {
				width: 308px;
			}
		
			.mt {
				margin-top: 2rem;
			}
		
			.enthusiasm {
				margin-bottom: 3rem;
			}
			.mandala_log_sides{
	left: -145px;
}
.mandala_log_sides.text-left{
	right: -186px;
}
.checkout-row .col-sm-7{
	width: 100%;
}
.check-left {
    padding-right: 15px;
    padding-left: 15px;
    border-right: none;
}
.check-left h4 {
    font-size: 20px;
}
.btn-check {
    padding: 10px 20px;
    font-size: 16px;
}
.prod-item .prod-info h3, .ship-total span, .ship-total p {
    font-size: 14px;
}
		}
		

		@media only screen and (max-width: 767px) {
		.row{
	--bs-gutter-x:0 !important
}
			.header .main-nav ul li a,
			.header .main-nav ul li ul li a {
				font-size: 14px;
			}
						.header .main-nav ul li {
							padding: 0 8px;
						}
						.menu-header.header .head-info {
							font-size: 13px;
							width: 100%;
							text-align: center;
							margin-bottom: 8px;
						}
												.header .main-nav {
													margin-top: 12px;
												}
								.header .header-login a {
									font-size: 13px;
								}
				.menu-header.header .header-login {
					width: 100%;
					text-align: center;
				}
		
				h3,
				.h3 {
					font-size: 20px;
				}
		
				#contModal .col-md-6.col-md-offset-1 {
					padding: 0 !important;
				}
		
				#contModal .col-md-6,
				#contModal .col-md-12 {
					padding: 0;

}
.mandala_log_sides{
	left: -130px;
}
.mandala_log_sides.text-left{
	right: -166px;
}
.checkout-wrapper {
    padding: 30px 0;
		}
		.ship-row {
    flex-wrap: wrap;
}
.ship-row .ship-col2, .ship-row .ship-col3 {
    width: 100%;
}
.ship-input input, .ship-input select {
    font-size: 14px;
    padding: 8px 10px;
}
.ship-btn {
    display: block;
    text-align: center;
}
.btn-check {
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
    padding: 10px 5px;
    font-size: 14px;
    width: 100%;
}
.ship-btn a {
    font-size: 14px;
	margin-left: 0;
}
	}

				@media only screen and (max-width: 600px) {
					.h1{
						font-size: 20px !important;
					}
					.mandala_log_sides{
	left: -100px;
	bottom: 10px;
}
.mandala_log_sides.text-left{
	right: -136px;
}
.about-container{
	padding: 0 12px;
}
				}

				@media only screen and (max-width: 600px) {
					.mandala_log_sides,.mandala_log_sides.text-left	{
						position: relative;
						left: 0;
						right: 0;
						bottom: 0;
					}
					.side_image{
						max-width: 530px;
						width: 100%;
					}
				}

				@media screen and (max-width:500px) {
					.logo-imgs{
						margin: 10px 0;
					}
					.img-style{
						padding-top: 1rem;
	margin-left: 0rem;
	flex-direction: column;
	align-items: center !important;
}
				}